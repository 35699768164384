import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import Layout from "../../components/Navigation/layout";
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
// @ts-ignore
import * as style from './index.module.scss';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
// @ts-ignore
import * as mainStyle from '../../theme/main.module.scss';
import {Client, handleApiError} from "../../services/ApiService";
import UploadField from "../../components/UploadField/UploadField";
import {toast} from "react-toastify";
import {connect, ConnectedProps} from "react-redux";
import AccountBanner from "../../components/AccountBanner/AccountBanner";
import Loading from "../../components/Loading/Loading";
import {setFotos, setHeaderImage} from '../../stores/registration/registrationSlice';
import {setLastEditedProductID} from '../../stores/member/memberSlice';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-light-svg-icons";
import {Link, navigate} from "gatsby";
import ScaledPrice from "./ScaledPrice/ScaledPrice";
import ProductGroupRow from "./ProductGroupRow/ProductGroupRow";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";

import {BrowserRouter as Router, useLocation} from 'react-router-dom';


import vendor from "../user/vendor";

const mapState = ({auth, member, registration}) => ({
    auth,
    member,
    registration
})

const mapDispatch = {
    setFotos,
    setHeaderImage,
    setLastEditedProductID
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>


interface FormInputs {
    Title: string,
    Category: number,
    Price: number,
    RefPrice: number,
    Tax: number,
    Description: string,
    Amount: number
    AgeCheck: boolean
}

const schema = yup.object().shape({
    Title: yup.string().required('Bitte gib einen Produktnamen an.'),
    Category: yup.string().required('Bitte wähle eine Kategorie aus.'),
    Price: yup.string().required('Bitte gib einen Preis an.'),
    RefPrice: yup.string().nullable(),
    Tax: yup.string().required('Bitte gib einen Steuersatz an.'),
    Amount: yup.number().required('Bitte gib eine Bestandszahl ein.'),
    Description: yup.string().optional(),
    AgeCheck: yup.boolean().optional()
})

const modifyProduct: FC<PropsFromRedux & any> = (
    {
        auth,
        member,
        registration,
        setFotos,
        setLastEditedProductID,
        setHeaderImage
    }
) => {
    const editorRef = useRef();
    const [editorLoaded, setEditorLoaded] = useState(false)
    // @ts-ignore
    const {CKEditor, ClassicEditor} = editorRef.current || {}
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });
    const [categories, setCategories] = useState([]);
    const [taxRates, setTaxRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [scaledPricesLoading, setScaledPricesLoading] = useState(false);
    const [title, setTitle] = useState();
    const [category, setCategory] = useState();
    const [taxRate, setTaxRate] = useState();
    const [price, setPrice] = useState();
    const [desc, setDesc] = useState();
    const [stock, setStock] = useState();
    const [ageCheck, setAgeCheck] = useState(false);
    const [scaledPrices, setScaledPrices] = useState([]);
    const [adminAnnotation, setAdminAnnotation] = useState('');
    const [sale, setSale] = useState(false);
    const [refPrice, setRefPrice] = useState(0)

    /**
     * @description Submit function to backend
     *
     *
     * @param data
     */
    function onSubmit(data: FormInputs) {
        let id = null
        if (typeof member.LastEditedProductID === 'number') {
            id = member.LastEditedProductID
        }
        let hasID = typeof parseInt(id) === 'number' && parseInt(id) > 0;
        let postData = {
            Title: data.Title,
            Description: desc ?? data.Description,
            Price: data.Price,
            Sale: sale ?? false,
            RefPrice: data.RefPrice ?? 0,
            TaxRateID: data.Tax != 0 ? data.Tax : taxRate,
            CategoryID: data.Category != 0 ? data.Category : category,
            VendorID: member.VendorID,
            OnStock: data.Amount,
            RequiresLegalAge: data.AgeCheck
        }

        if (registration.headerImage[0]?.ID) {
            postData['MainImageID'] = registration.headerImage[0].ID;
        } else {
            postData['MainImageID'] = 0;
        }

        if (hasID) {

            // existing product

            Client.Auth.authPut(auth.Key, 'Product', id, postData)
                .then((res) => {
                    if (registration.fotos.length) {
                        Client.Product.removeImages(auth.Key, res.data.ID).then(() => {
                            Client.Product.addImages(auth.Key, res.data.ID, registration.fotos).then(() => {
                                navigate('/user/vendor?tab=produkte').then(() => toast.success('Produkt mit Bildern erfolgreich aktualisiert!'));
                            }).catch(handleApiError);
                        }).catch(handleApiError);
                    } else {
                        Client.Product.removeImages(auth.Key, res.data.ID).then(() => {
                            navigate('/user/vendor?tab=produkte').then(() => toast.success('Produkt erfolgreich aktualisiert!'));
                        }).catch(handleApiError);
                    }
                }).catch(handleApiError)
        } else {

            // new product
            Client.Auth.authPostNoID(auth.Key, 'Product', postData)
                .then((res) => {
                    setLastEditedProductID(res.data.ID);
                    if (registration.fotos.length) {
                        Client.Product.removeImages(auth.Key, res.data.ID).then(() => {
                            Client.Product.addImages(auth.Key, res.data.ID, registration.fotos).then(() => {
                                navigate('/user/vendor?tab=produkte').then(() => toast.success('Produkt ' + data.Title + ' erfolgreich angelegt!'));
                            }).catch(handleApiError);
                        }).catch(handleApiError);
                    } else {
                        navigate('/user/vendor?tab=produkte').then(() => toast.success('Produkt ' + data.Title + ' erfolgreich angelegt!'));
                    }
                }).catch(handleApiError);
        }
    }

    useEffect(() => {
        let id = null
        if (typeof member.LastEditedProductID === 'number') {
            id = member.LastEditedProductID
        }

        if (typeof parseInt(id) === 'number' && parseInt(id) > 0) {
            Client.Raw.get('Product/' + id).then((res) => {
                setCategory(res.data.CategoryID);
                setValue('Category', res.data.CategoryID);
                setTaxRate(res.data.TaxRateID);
                setValue('Tax', res.data.TaxRateID)
            })
        }
    }, [category, taxRate])

    useEffect(() => {
        let id = null
        if (typeof member.LastEditedProductID === 'number') {
            id = member.LastEditedProductID
        }

        if (typeof parseInt(id) === 'number' && parseInt(id) > 0) {
            Client.Raw.get('Product/' + id).then((res) => {


                setSale(res.data.Sale)
                setRefPrice(res.data.RefPrice)
                setTitle(res.data.Title);
                setCategory(res.data.CategoryID);
                setValue('Category', res.data.CategoryID);
                setValue('Tax', res.data.TaxRateID);
                setTaxRate(res.data.TaxRateID);
                setPrice(res.data.Price);
                setDesc(res.data.Description);
                setStock(res.data.OnStock);
                setAdminAnnotation(res.data.AdminAnnotation);
                setAgeCheck(res.data.RequiresLegalAge);
                setScaledPrices(res.data.ScaledPrices ?? []);
                setHeaderImage([res.data.MainImage] ?? []);
                setFotos(res.data.Images ?? []);
                setLoading(false);
            })
        } else {
            setHeaderImage([]);
            setFotos([]);
            setLoading(false);
        }
        Client.Raw.get('Category/tree/flat').then((res) => setCategories(res.data))
        Client.Raw.get('TaxRate').then((res) => setTaxRates(res.data));
        // @ts-ignore
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // deprecated in v3
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
        }
        setEditorLoaded(true)

    }, [])

    function copyProduct() {
        setTimeout(() => {
            Client.Auth.authGetNoID(auth.Key, 'Product/' + member.LastEditedProductID + '/duplicateProductInGroup', {}).then((res) => {
                setLastEditedProductID(res.data.ID);
                location.reload();
            })
        }, 1000)
    }

    const onClickAddScalePrice = () => {
        setScaledPricesLoading(true);
        Client.Auth.authPostNoID(auth.Key, 'ScaledPrice', {
            ProductID: member.LastEditedProductID
        })
            .then(() => {
                Client.Raw
                    .get('Product/' + member.LastEditedProductID)
                    .then((response) => {
                        setScaledPrices(response.data.ScaledPrices ?? []);
                        setScaledPricesLoading(false);
                    })
            })
    }

    return (
        <Layout>
            {member.LastEditedProductID ?
                <>
                    <AccountBanner isVendor={true}
                                   title={title ? ("Produkt " + title + " bearbeiten") : "Produkt bearbeiten"}/>
                    <Container>
                        {
                            adminAnnotation ?
                                <div className={style.adminAnnotation}>
                                    <Row className={"h-100"}>
                                        <Col lg={3} className={"d-flex justify-content-center align-items-center"}>
                                            <FontAwesomeIcon className={style.shieldIcon} icon={faExclamationTriangle}/>
                                        </Col>
                                        <Col lg={9} className={"d-flex align-items-center"}>
                                            <div className={"pe-2"}>
                                                {adminAnnotation}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                : null
                        }
                    </Container>
                </>
                :
                <AccountBanner isVendor={true} title={"Produkt anlegen"}/>
            }
            <div className={style.customContainer + ' pt-4'}>
                {loading ?
                    <Loading type={"border"} message={""}/>
                    :
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className={"mb-5"}>
                                <Col xs={6}>
                                    <Link
                                        to="/user/vendor?tab=produkte"
                                        className={style.backToOverview}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft}/>
                                        Zurück zur Übersicht
                                    </Link>

                                </Col>
                                <Col xs={6} className={"text-end position-relative"}>
                                    <Button className={style.submitButton} type={"submit"}>
                                        Speichern
                                    </Button>
                                </Col>
                            </Row>
                            <Row className={"mb-5"}>
                                <Col lg={6}>
                                    {typeof member.LastEditedProductID === 'number' ?
                                        <Row>
                                            <ProductGroupRow member={member} authKey={auth.Key}/>
                                        </Row> : null}
                                </Col>
                                <Col lg={6} className={"text-end d-flex align-items-end justify-content-end " + style.copyProduct}>
                                    <Button className={style.submitButton} type={"button"} onClick={copyProduct}>
                                        Produkt kopieren
                                    </Button>
                                </Col>
                            </Row>
                            <Row className={"mb-5"}>
                                <Col lg={6}>
                                    <Form.Group controlId="formTitle" className="mb-2">
                                        <Form.Label className={style.inputLabel}>Titel</Form.Label>
                                        <Form.Control type="text" className={style.inputField}
                                                      defaultValue={title ? title : ''} {...register('Title')}/>
                                        <Form.Text className={mainStyle.errorMsg}>
                                            {errors.Title?.message}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="formCat">
                                        <Form.Label className={style.inputLabel}>Kategorie</Form.Label>
                                        <Form.Control
                                            as="select"
                                            className={style.dropDown + ' form-select'}
                                            {...register('Category')}
                                        >
                                            <option disabled value={"0"}>-- Bitte auswählen --</option>
                                            {categories.map((cat, index) => {
                                                return (
                                                    <option value={cat.ID} key={index} selected={category === cat.ID}>
                                                        {cat.Path}
                                                    </option>
                                                )
                                            })}
                                        </Form.Control>
                                        <Form.Text className={mainStyle.errorMsg}>
                                            {errors.Category?.message}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="formStock">
                                        <Form.Label className={style.inputLabel}>Lagerstand</Form.Label>
                                        <Form.Control type="number" className={style.inputField}
                                                      defaultValue={stock ? stock : 0} {...register('Amount')}/>
                                        <Form.Text className={mainStyle.errorMsg}>
                                            {errors.Amount?.message}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="formTax">
                                        <Form.Label className={style.inputLabel}>Steuersatz</Form.Label>
                                        <Form.Control as="select" className={style.dropDown + ' form-select'}
                                                      {...register('Tax')}>
                                            <option disabled>-- Bitte auswählen --</option>
                                            {taxRates.map((tax, index) => {
                                                return (
                                                    <option value={tax.ID} key={index} selected={taxRate === tax.ID}>
                                                        {tax.Title}
                                                    </option>
                                                )
                                            })}
                                        </Form.Control>
                                        <Form.Text className={mainStyle.errorMsg}>
                                            {errors.Tax?.message}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="formAgeCheck">
                                        <Form.Label className={style.inputLabel}>Altersbestätigung</Form.Label>
                                        <Form.Check
                                            {...register('AgeCheck')}
                                            type="checkbox"
                                            onChange={(e) => setAgeCheck(e.target.value)}
                                            id="custom-switch"
                                            defaultChecked={ageCheck}
                                            label="Um dieses Produkt zu bestellen muss erst ein Mindestalter von 18 Jahren bestätigt werden."
                                        />
                                        <Form.Text className={mainStyle.errorMsg}>

                                            {errors.AgeCheck?.message}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group controlId="formCheckSale">
                                        <Form.Label className={style.inputLabel}>Produkt vergünstigt?</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => setSale(!sale)}
                                            id="custom-switch-2"
                                            defaultChecked={sale}
                                            label="Ist dieses Produkt momentan vergünstigt?"
                                        />
                                        <Form.Text className={mainStyle.errorMsg}>
                                        </Form.Text>
                                    </Form.Group>

                                    {sale ? <Col xs={5} lg={6}>
                                        <Form.Group controlId="formRefPrice" className="mb-2">
                                            <Form.Label className={style.inputLabel}>Referenzpreis (Alter Preis)</Form.Label>
                                            <Form.Control type="text" className={style.inputField}
                                                          defaultValue={refPrice ? refPrice : '0.00'} {...register('RefPrice')}/>
                                            <Form.Text className={mainStyle.errorMsg}>
                                                {errors.Price?.message}
                                            </Form.Text>
                                        </Form.Group>
                                    </Col> : null}
                                </Col>
                                <Col lg={12}>
                                    <Row className={"mb-5"}>
                                        <Col xs={5} lg={6}>
                                            <Form.Group controlId="formPrice" className="mb-2">
                                                <Form.Label className={style.inputLabel}>Preis</Form.Label>
                                                <Form.Control type="text" className={style.inputField}
                                                              defaultValue={price ? `${price}`.replace('.', ',') : ''} {...register('Price')}/>
                                                <Form.Text className={mainStyle.errorMsg}>
                                                    {errors.Price?.message}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        {typeof member.LastEditedProductID === 'number' || member.LastEditedProductID > 1 ?

                                            <Col xs={7} lg={6} className={"d-flex align-items-end"}>
                                                <Button
                                                    type={"button"}
                                                    variant={'primary'}
                                                    className={style.submitButton + " mb-2"}
                                                    onClick={onClickAddScalePrice}
                                                    disabled={scaledPricesLoading}
                                                >
                                                    Mengenrabatt hinzufügen
                                                </Button>
                                            </Col> : null
                                        }
                                    </Row>
                                    {typeof member.LastEditedProductID === 'number' || member.LastEditedProductID > 1 ?
                                        <Row>
                                            {scaledPricesLoading ?
                                                <Col lg={6}><Loading type="border" message="Lade..."/></Col>
                                                :
                                                scaledPrices.map((obj: any, index: number) =>
                                                    <ScaledPrice authKey={auth.Key} obj={obj} key={index}/>
                                                )
                                            }
                                        </Row> : null}
                                </Col>
                                <Col lg={12}>
                                    <Form.Group controlId="formDescription" className="mb-2">
                                        <Form.Label className={style.inputLabel}>Beschreibung</Form.Label>
                                        {editorLoaded ?
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{removePlugin: ['ImageUpload'], height: 500}}
                                                data={desc}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDesc(data);
                                                }}
                                            /> : <Loading type={"border"} message={"Editor wird geladen."}/>
                                        }
                                        {/*<Form.Control as="textarea" rows={8}
                                                  resize={false}
                                                  className={style.textArea}
                                                  defaultValue={desc}  {...register('Description')}/>*/}

                                        <Form.Text className={mainStyle.errorMsg}>
                                            {errors.Description?.message}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <h2 className={style.inputLabel}>Hauptbild</h2>
                                    <UploadField maxFiles={1}/>
                                </Col>
                                <Col lg={12}>
                                    <h2 className={style.inputLabel}>Weitere Bilder</h2>
                                    <UploadField/>
                                </Col>
                                <Col lg={12} className={'text-end mt-3'}>
                                    <Button className={style.submitButton} type={"submit"}>
                                        Speichern
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }

            </div>

        </Layout>
    )
}

export default connector(modifyProduct);
