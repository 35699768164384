import * as React from "react";
import {FC} from "react";
// @ts-ignore
import * as style from "./AccountBanner.module.scss";

type AccountBannerProps = {
    user?: any,
    isVendor?: boolean,
    title?: string,
    disableMobile?: boolean
}

const AccountBanner: FC<AccountBannerProps> = ({user, isVendor, title, disableMobile}) => {
    return (
        <div className={style.container + " container-fluid position-relative " + (disableMobile ? ' d-none d-md-block' : '')}>
            {isVendor ?
                <div className={style.background}
                     style={{background: `url('../../../hintergrund_pattern-2x.png')`}}/>
                : <div className={style.background}
                       style={{background: `url('../../../hg_mein-account-header-2x.png')`}}/>
            }
            {user ?
                <div className={isVendor ? style.vendorContent : style.userContent}>
                    <h4>
                        {isVendor ?
                            <span> Händlerportal </span> : <span> Mein Account </span>
                        }
                    </h4>
                    <span className={isVendor ? style.nameRow : null + ' text-center'}>
                        {user ?
                            <>
                                <h2>
                                    {user.FirstName}
                                </h2>
                                <h3>
                                    {user.Email}
                                </h3>
                            </> : null
                        }
                    </span>
                </div> :
                <div className={isVendor ? style.titleRow : style.titleRowDark}>
                    <h4>
                        {title}
                    </h4>
                </div>
            }
        </div>
    )
}

export default AccountBanner;
